<template>
  <div v-if="urtica" class="home">
    <app-header v-if="urtica.header" :header="urtica.header" />
    <page-intro v-if="urtica.intro" :intro="urtica.intro" />
    <advantages-block
      v-if="urtica.advantages"
      :advantages="urtica.advantages"
    />
    <solutions-block v-if="urtica.solutions" :solutions="urtica.solutions" />
    <who-we-help v-if="urtica.help" :help="urtica.help" />
    <industries-block
      v-if="urtica.industries"
      :industries="urtica.industries"
    />
    <company-block v-if="urtica.company" :company="urtica.company" />
    <app-footer v-if="urtica.footer" :footer="urtica.footer" />
    <contact-modal />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    PageIntro: () => import("@/components/blocks/PageIntro.vue"),
    AdvantagesBlock: () => import("@/components/blocks/AdvantagesBlock.vue"),
    SolutionsBlock: () => import("@/components/blocks/SolutionsBlock.vue"),
    WhoWeHelp: () => import("@/components/blocks/WhoWeHelp.vue"),
    IndustriesBlock: () => import("@/components/blocks/IndustriesBlock.vue"),
    CompanyBlock: () => import("@/components/blocks/CompanyBlock.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    ContactModal: () => import("@/components/modals/ContactModal.vue"),
  },
  data() {
    return {
      urtica: null,
    };
  },
  created() {
    axios("https://urtica.ae/get_data.php", {
      method: "GET",
    }).then((res) => {
      console.log(res);
      this.urtica = res.data;
    });
  },
};
</script>
